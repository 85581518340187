<template>
  <div class="box">
    <div
      class="bg"
      style="position: absolute;top:0;width:100%;z-index:99"
    >
      <div style="width:340px;height:200px;display: flex;justify-content:center;margin:0 auto;position:absolute;top:25%;left:50%;transform:translateX(-50%);background-color:#fff;border-radius: 10%;">
        <!-- <div style="width:74px;height:74px;border-radius: 50%;position: absolute;top:5%;left:38.9%;background-color:#f2fd95;overflow: hidden;">
          <img style="width:100%;height:100%" src="https://ceshiren.com/uploads/default/original/1X/809c63f904a37bc0c6f029bbaf4903c27f03ea8a.png" alt="">
        </div>
        <p style="font-size:18px;color:#967b3c;margin: 0;position: absolute;top:95px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">用户名字</p>
        <p style="font-size:16px;color:#967b3c;margin: 0;position: absolute;top:130px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">你当前获得助力 1234 </p> -->
        <div
          v-if="top_three[1]"
          style="width: 113px;height: 100%;display: flex;flex-direction: column;align-items: center;padding-top:30px"
        >
          <div style="width:74px;height:74px;border-radius: 50%;background-color:#f2fd95;overflow: hidden;">
            <img
              style="width:100%;height:100%"
              :src="top_three[1].headimgurl"
              alt=""
            >
          </div>
          <div style="font-size: 16px;text-align:center;margin-top:7px;line-height: 1;">{{top_three[1].nickname}}</div>
          <div style="font-size: 16px;text-align:center;margin-top:5px;line-height: 1;">助力值 {{top_three[1].count}}</div>
        </div>
        <div
          v-if="top_three[0]"
          style="width: 113px;height: 100%;display: flex;flex-direction: column;align-items: center;padding-top:30px;margin-top: -25px;background-color: #fff;border-top-left-radius: 10px;border-top-right-radius: 10px;"
        >
          <div style="width:74px;height:74px;border-radius: 50%;background-color:#f2fd95;overflow: hidden;">
            <img
              style="width:100%;height:100%"
              :src="top_three[0].headimgurl"
              alt=""
            >
          </div>
          <div style="font-size: 16px;text-align:center;margin-top:7px;line-height: 1;">{{top_three[0].nickname}}</div>
          <div style="font-size: 16px;text-align:center;margin-top:5px;line-height: 1;">助力值 {{top_three[0].count}}</div>
        </div>
        <div
          v-if="top_three[2]"
          style="width: 113px;height: 100%;display: flex;flex-direction: column;align-items: center;padding-top:30px"
        >
          <div style="width:74px;height:74px;border-radius: 50%;background-color:#f2fd95;overflow: hidden;">
            <img
              style="width:100%;height:100%"
              :src="top_three[2].headimgurl"
              alt=""
            >
          </div>
          <div style="font-size: 16px;text-align:center;margin-top:7px;line-height: 1;">{{top_three[2].nickname}}</div>
          <div style="font-size: 16px;text-align:center;margin-top:5px;line-height: 1;">助力值 {{top_three[2].count}}</div>
        </div>
      </div>
    </div>
    <div style="width:100%;margin:0 auto;position:absolute;top:260px;left:50%;transform:translateX(-50%);background-color:#fff;height:60vh;overflow: auto;">
      <div
        v-for="(item,index) in rank"
        :key="index"
        style="width:100%;height:80px;padding: 0 26px;display:flex;justify-content:space-between;align-items: center;border-bottom: 1px solid #ccc; font-size:16px"
      >
        <div style="display: flex;align-items: center;">
          <div style="font-size:18px;min-width: 30px;">{{item.index}}</div>
          <div style="width: 60px;height:60px;border-radius:50%;overflow: hidden;margin-left: 5px;">
            <img
              style="width:100%;height:100%"
              :src="item.headimgurl"
              alt=""
            >
          </div>
          <!-- here -->
          <div style="margin-left: 20px;">{{nicknameFn(item.nickname,9)}}</div>
        </div>
        <div>{{item.count}}</div>
      </div>
      <div
        v-if="rank.length>6"
        style="width:100%;height:150px"
      ></div>
    </div>

    <div
      v-if="self.nickname"
      style="width:100%;height:80px;position: fixed;bottom: 0px;background-color: #fb8c00;padding: 0 26px;display:flex;justify-content:space-between;align-items: center;border-bottom: 1px solid #ccc; font-size:16px"
    >
      <div style="display: flex;align-items: center;">
        <div style="font-size:18px;min-width: 30px;">{{self.index}}</div>
        <div style="width: 60px;height:60px;border-radius:50%;overflow: hidden;margin-left: 5px;">
          <img
            style="width:100%;height:100%"
            :src="self.headimgurl"
            alt=""
          >
        </div>
        <div style="margin-left: 20px;">{{self.nickname}}</div>
      </div>
      <div>{{self.count}}</div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      origin_: '',
      title: '',
      top_three: [{}, {}, {}],
      rank: [{}],
      self: {
        count: null,
        headimgurl: "",
        index: null,
        nickname: "",
        uid: "",
      }
    }
  },
  methods: {

    //获取排行榜信息
    async getRanings () {
      this.origin_ = location.origin
      let params = {
        project: this.$route.query.project,
        uid: this.$route.query.uid
      }
      const res = await axios.get(`${this.origin_}/backend/poster/profile`, { params })
      console.log(res);
      if (res.data.self) this.self = res.data.self
      this.title = res.data.title
      this.top_three = []
      for (let i = 0; i < 3; i++) {
        // if (res.data.rank.length > i) {
        this.top_three.push(res.data.rank[i])
        if (i === 2) {
          this.rank = res.data.rank.slice(3, res.data.rank.length + 1)
          break
        }
        // }
      }
      console.log(this.top_three);
      console.log(this.rank);
      // this.rank = res.data.rank
    },

    //昵称处理
    nicknameFn (nickname, num) {
      if (nickname.length > num) {
        return nickname.substring(0, num) + '···'
      } else {
        return nickname
      }
    },
  },
  created () {
    this.getRanings()
  },
  watch: {
    title: {
      handler (newval, oldval) {
        if (newval !== oldval) document.title = this.title
      }
    }
  }
}
</script>

<style scoped>
.box {
  position: relative;
  top: 0;
  z-index: 99;
  height: 100%;
  background-color: #cccccc1f;
}
.bg {
  width: 100%;
  height: 200px;
  background-color: #fb8c00;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
}
</style>